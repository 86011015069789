import React from "react"
import FAQList from "../components/FAQList"
import PageHeadBanner from "../components/PageHeadBanner"
import Seo from "../components/Seo"
import withLayout from "../layout/withLayout"
const FAQ = withLayout(({ siteData }) => {
  return (
    <>
      <Seo title={"Frequently Asked Questions"} siteMetadata={siteData} />
      <PageHeadBanner title={"FAQ"} subtitle={"Frequently Asked Questions"} />

      <section className="pb-100 pt-70">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <FAQList />
            </div>
          </div>
        </div>
      </section>
    </>
  )
})

export default FAQ
