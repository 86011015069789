import React, { useEffect } from "react"
const FAQList = ({ max = null }) => {
  useEffect(() => {
    const { $ } = window
    $(".accordion")
      .find(".accordion-title")
      .on("click", function () {
        $(this).toggleClass("active")
        $(this).next().slideToggle("fast")
        $(".accordion-content").not($(this).next()).slideUp("fast")
        $(".accordion-title").not($(this)).removeClass("active")
      })
  }, [])
  return (
    <div className="faq-accordion" style={{ marginTop: 40 }}>
      <ul className="accordion">
        <li className="accordion-item">
          <a className="accordion-title" href="#">
            <i className="ri-add-line"></i>
            01. What are the Streams Available?
          </a>
          <div className="accordion-content">
            <p>
              Darwin Academy Mirza has Availability of Arts, Science and
              Commerce Stream in both English and Assamese Medium.
            </p>
          </div>
        </li>
        <li className="accordion-item">
          <a className="accordion-title" href="#">
            <i className="ri-add-line"></i>
            02. What is the Medium of Study?
          </a>
          <div className="accordion-content">
            <p>
              All streams i.e. Arts, Science and Commerce Stream is availabe in
              both English and Assamese Medium.
            </p>
          </div>
        </li>

        <li className="accordion-item">
          <a className="accordion-title" href="#">
            <i className="ri-add-line"></i>
            03. What is the eligibility for a Scholarship ?
          </a>
          <div className="accordion-content">
            <p>
              Scholarship is only available for Meritorious students and
              Economically weaker students. You can do an equiry to check if you
              are eligible.
            </p>
          </div>
        </li>

        <li className="accordion-item">
          <a className="accordion-title" href="#">
            <i className="ri-add-line"></i>
            04. What are the Subjects availabe under Arts Stream ?
          </a>
          <div className="accordion-content">
            <p>
              Arts Stream has Following Subjects - Economics, Political Science,
              Logic &amp; Philosophy Education, Adv. Assamese and History in
              both English and Assamese Medium.
            </p>
          </div>
        </li>

        <li className="accordion-item">
          <a className="accordion-title" href="#">
            <i className="ri-add-line"></i>
            05. What are the Subjects availabe under Commerce Stream ?
          </a>
          <div className="accordion-content">
            <p>
              Commerce Stream has Following Subjects - Accountancy, Business
              Studies, Banking (Finance) Economics, Commercial Mathematics and
              Statistics in both English and Assamese Medium.
            </p>
          </div>
        </li>

        <li className="accordion-item">
          <a className="accordion-title" href="#">
            <i className="ri-add-line"></i>
            06. What are the Subjects availabe under Science Stream ?
          </a>
          <div className="accordion-content">
            <p>
              Science Stream has Following Subjects - Physics, Chemistry,
              Mathematics and Biology in both English and Assamese Medium.
            </p>
          </div>
        </li>
      </ul>
    </div>
  )
}

export default FAQList
